var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.docente
                ? _vm.user.academicCode != _vm.docente.academicCode
                : false,
              expression:
                "docente?user.academicCode != docente.academicCode: false"
            }
          ],
          staticClass: "mb-10 text-center",
          attrs: { cols: "12" }
        },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "black" } }, [
                _vm._v("school")
              ]),
              _c("strong", [
                _vm._v(_vm._s(_vm.docente ? _vm.docente.name : ""))
              ])
            ],
            1
          ),
          _c("hr")
        ]
      ),
      _c(
        "v-col",
        { staticClass: "mb-5 text-center", attrs: { cols: "12" } },
        [_c("legenda")],
        1
      ),
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Orientandos")]),
        _c("hr")
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.orientandos,
              "items-per-page": _vm.orientandos.length,
              "hide-default-footer": "",
              "no-data-text": "Ainda não há dados disponíveis para a consulta",
              loading: _vm.showLoadingGui,
              "loading-text": "Buscando orientandos..."
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showStudent(props.item)
                          }
                        }
                      },
                      [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.name))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.course))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(" " + _vm._s(props.item.period) + " "),
                          props.item.couseBreakes > 0
                            ? _c(
                                "label",
                                { staticClass: "overline text-monospace" },
                                [
                                  _vm._v(
                                    "[" + _vm._s(props.item.couseBreakes) + "]"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.englishColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.englishSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.englishStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.creditsColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.creditsSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.creditsStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class:
                                                  props.item.orientatorColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.orientatorSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.orientatorStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.pedColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.pedSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.pedStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.crColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.crSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.crStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.examColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.examSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.examStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.coorientandos.length > 0,
              expression: "coorientandos.length > 0"
            }
          ],
          attrs: { cols: "12" }
        },
        [_c("h3", [_vm._v("Coorientandos")]), _c("hr")]
      ),
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.coorientandos.length > 0,
              expression: "coorientandos.length > 0"
            }
          ],
          attrs: { cols: "12" }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.coorientandos,
              "items-per-page": 100,
              "hide-default-footer": "",
              "no-data-text": "Não foram encontrados coorientandos",
              loading: _vm.showLoadingCoGui,
              "loading-text": "Buscando coorientandos..."
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showStudent(props.item)
                          }
                        }
                      },
                      [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.name))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.course))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(" " + _vm._s(props.item.period) + " "),
                          props.item.couseBreakes > 0
                            ? _c(
                                "label",
                                { staticClass: "overline text-monospace" },
                                [
                                  _vm._v(
                                    "[" + _vm._s(props.item.couseBreakes) + "]"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.englishColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.englishSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.englishStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.creditsColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.creditsSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.creditsStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class:
                                                  props.item.orientatorColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.orientatorSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.orientatorStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.pedColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.pedSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.pedStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.crColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.crSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.crStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.examColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.examSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.examStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }