<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12" class="mb-10 text-center" v-show="docente?user.academicCode != docente.academicCode: false">
      <div class="title">
        <v-icon color="black" class="mr-2">school</v-icon
        ><strong>{{ docente?docente.name:'' }}</strong>
      </div>
      <hr />
    </v-col>
    <v-col cols="12" class="mb-5 text-center">
      <legenda></legenda>
    </v-col>

    <v-col cols="12">
      <h3>Orientandos</h3>
      <hr />
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="orientandos"
        :items-per-page="orientandos.length"
        hide-default-footer
        no-data-text="Ainda não há dados disponíveis para a consulta"
        :loading="showLoadingGui"
        loading-text="Buscando orientandos..."
      >
        <template v-slot:item="props">
          <tr @click="showStudent(props.item)">
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.course }}</td>
            <td class="text-xs-left">
              {{ props.item.period }}
              <label
                v-if="props.item.couseBreakes > 0"
                class="overline text-monospace"
                >[{{ props.item.couseBreakes }}]</label
              >
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.englishStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.englishColor"
                    :size="avatarSize"
                    v-on="on"
                  >
                    <v-icon color="black" small>{{
                      props.item.englishSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.creditsStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.creditsColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.creditsSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.orientatorStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.orientatorColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.orientatorSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.pedStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.pedColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.pedSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.crStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.crColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{ props.item.crSpan }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.examStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.examColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.examSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" v-show="coorientandos.length > 0">
      <h3>Coorientandos</h3>
      <hr />
    </v-col>
    <v-col cols="12" v-show="coorientandos.length > 0">
      <v-data-table
        :headers="headers"
        :items="coorientandos"
        :items-per-page="100"
        hide-default-footer
        no-data-text="Não foram encontrados coorientandos"
        :loading="showLoadingCoGui"
        loading-text="Buscando coorientandos..."
      >
        <template v-slot:item="props">
          <tr @click="showStudent(props.item)">
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.course }}</td>
            <td class="text-xs-left">
              {{ props.item.period }}
              <label
                v-if="props.item.couseBreakes > 0"
                class="overline text-monospace"
                >[{{ props.item.couseBreakes }}]</label
              >
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.englishStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.englishColor"
                    :size="avatarSize"
                    v-on="on"
                  >
                    <v-icon color="black" small>{{
                      props.item.englishSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.creditsStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.creditsColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.creditsSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.orientatorStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.orientatorColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.orientatorSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.pedStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.pedColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.pedSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.crStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.crColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{ props.item.crSpan }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.examStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.examColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.examSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { studentUtils } from "@/utils/studentUtils";
import { statusInterpreter } from "@/utils/statusInterpreter";
import * as storage from '@/pages/auth/storage'

import Legenda from "@/components/common/Legenda";

export default {
  name: "Professor",
  components: { Legenda },
  data() {
    return {
      docente: {},
      academicCode: "",
      orientandos: [],
      coorientandos: [],
      search: "",
      showLoadingGui: true,
      showLoadingCoGui: true,
      avatarSize: "30",
      user: null,
      headers: [
        { sortable: true, text: "RA", value: "academicCode", align: "left" },
        { sortable: true, text: "Aluno", value: "name", align: "left" },
        { sortable: true, text: "Curso", value: "course", align: "left" },
        { sortable: true, text: "Período", value: "period", align: "left" },
        { sortable: true, text: "Inglês", value: "englishSort", align: "left" },
        {
          sortable: true,
          text: "Créditos",
          value: "creditsSort",
          align: "left",
        },
        {
          sortable: true,
          text: "Orientação",
          value: "orientatorSort",
          align: "left",
        },
        { sortable: true, text: "PED", value: "pedSort", align: "left" },
        { sortable: true, text: "CR", value: "crSort", align: "left" },
        { sortable: true, text: "Exame", value: "examEQMSort", align: "left" },
      ],
    };
  },

  created() {
    this.user = storage.getLocalData().user;
    this.academicCode = this.$route.params.academic_code;
    this.docente = this.$route.params.professor;
    this.getGuidances(this.academicCode);
    this.getCoGuidances(this.academicCode);
  },

  computed: {
    ...mapState("professor", [
      "facultyProfilePageGuidance",
      "facultyProfilePageCoGuidance"
    ]),
  },

  methods: {
    ...mapActions("professor", [
      "ActionGetFacultyProfilePageGuidance",
      "ActionGetFacultyProfilePageCoGuidance"
    ]),

    async getGuidances(academicCode) {
      this.showLoadingGui = true;
      try {
        await this.ActionGetFacultyProfilePageGuidance(academicCode);
        this.showLoadingGui = false;
      } catch (err) {
        console.error(err);
      }
    },

    async getCoGuidances(academicCode) {
      this.showLoadingCoGui = true;
      try {
        await this.ActionGetFacultyProfilePageCoGuidance(academicCode);
        this.showLoadingCoGui = false;
      } catch (err) {
        console.error(err);
      }
    },

    refreshGuidances(students) {
      let items = [];
      if (students) {
        students.map((student) => {
          let academicLife = studentUtils.returnActiveAcademicLife(
            student.academicLifes
          );

          if (academicLife) {
            let totalRules = student.rulesByAcademicLife;
            let rules = totalRules[academicLife.id];

            let englishStatus;
            let englishColor;
            let englishSpan;
            let creditsStatus;
            let creditsColor;
            let creditsSpan;
            let orientatorStatus;
            let orientatorColor;
            let orientatorSpan;
            let pedStatus;
            let pedColor;
            let pedSpan;
            let crStatus;
            let crColor;
            let crSpan;
            let examStatus;
            let examColor;
            let examSpan;

            rules.map((rule) => {
              if (rule.mensagens !== null && rule.mensagens.length > 0) {
                const mensagem = studentUtils.arrayMin(rule.mensagens);
                const tipo = rule.tipo;

                switch (tipo) {
                  case "proficiencia":
                    englishStatus = statusInterpreter.desc(mensagem.descricao);
                    englishColor = statusInterpreter.cor(mensagem.codigo);
                    englishSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "creditos":
                    creditsStatus = statusInterpreter.desc(mensagem.descricao);
                    creditsColor = statusInterpreter.cor(mensagem.codigo);
                    creditsSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "orientacao":
                    orientatorStatus = statusInterpreter.desc(
                      mensagem.descricao
                    );
                    orientatorColor = statusInterpreter.cor(mensagem.codigo);
                    orientatorSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "ped":
                    pedStatus = statusInterpreter.desc(mensagem.descricao);
                    pedColor = statusInterpreter.cor(mensagem.codigo);
                    pedSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "cr":
                    crStatus = statusInterpreter.desc(mensagem.descricao);
                    crColor = statusInterpreter.cor(mensagem.codigo);
                    crSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "eqm":
                    examStatus = statusInterpreter.desc(mensagem.descricao);
                    examColor = statusInterpreter.cor(mensagem.codigo);
                    examSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                  case "eqe":
                    examStatus = statusInterpreter.desc(mensagem.descricao);
                    examColor = statusInterpreter.cor(mensagem.codigo);
                    examSpan = statusInterpreter.span(mensagem.codigo);
                    break;
                }
              }
            });

            // inserindo um novo objeto
            items.push({
              academicCode: student.academicCode,
              name: student.name,
              course: academicLife.course.name,
              period: academicLife.currentPeriod,
              couseBreakes:
                academicLife.courseBreaks != null
                  ? academicLife.courseBreaks.length
                  : 0,
              englishStatus: englishStatus,
              englishColor: englishColor,
              englishSpan: englishSpan,
              creditsStatus: creditsStatus,
              creditsColor: creditsColor,
              creditsSpan: creditsSpan,
              orientatorStatus: orientatorStatus,
              orientatorColor: orientatorColor,
              orientatorSpan: orientatorSpan,
              pedStatus: pedStatus,
              pedColor: pedColor,
              pedSpan: pedSpan,
              crStatus: crStatus,
              crColor: crColor,
              crSpan: crSpan,
              examStatus: examStatus,
              examColor: examColor,
              examSpan: examSpan,
            });
          }
        });
      }
      return items;
    },

    showStudent(student) {
      this.$router.push({
        name: "Student",
        params: { academic_code: student.academicCode, student: student },
      });
    },
  },

  watch: {
    facultyProfilePageGuidance(newValue) {
      this.orientandos = [];
      this.orientandos = this.refreshGuidances(newValue);
      this.showLoadingGui = false;
    },

    facultyProfilePageCoGuidance(newValue) {
      this.coorientandos = [];
      this.coorientandos = this.refreshGuidances(newValue);
      this.showLoadingCoGui = false;
    },
  },
};
</script>

<style scoped>
td {
  cursor: pointer;
}

.md2 {
  max-width: none;
}
</style>